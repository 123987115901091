import React, { Component } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import jquery from 'jquery/dist/jquery.js';
import Pusher from 'pusher-js';
import beautify from 'js-beautify'
import pushid from 'pushid';
import axios from 'axios';
import {Badge} from 'react-bootstrap'
import atclogo from '../../images/loader.png';
import '../../App.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/lucario.css';

import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/css/css';
import 'codemirror/mode/javascript/javascript';

    class pgassessments2 extends Component{
      constructor(props) {
        super(props);
        this.state = {
          id:'',
          html:'',
          taskRef:this.props.taskRef,
          title:'',
          taskDescription:'',
          taskA:'',
          taskB:'',
          taskC:'',
          taskD:'',
          taskE:'',
          taskF:'',
          taskG:'',
          taskByteValue:'',
          cloudImage:'',
        };

  this.runCode = this.runCode.bind(this)
  this.resetSubmissionStatusToFalse = this.resetSubmissionStatusToFalse.bind(this);
  this.textReader = this.textReader.bind(this)
  this.Imageupload = this.Imageupload.bind(this)
   }

    async componentDidUpdate() {
     //console.log(this.props)

    }

     async componentDidMount() {
    
         console.log(this.props.taskRef);
          this.resetSubmissionStatusToFalse()

        //const {match:{params}} = this.props   
        //console.log(params.topidRef);
        

         // this.props.taskStatus(false)
         // this.props.byteState(this.state.taskByteValue)
      }


      async Imageupload(e) {

        e.preventDefault()
        // Configure Cloudinary
        // with the credentials on
        // your Cloudinary dashboard
      await  jquery.cloudinary.config({ cloud_name: 'www-code4teen-tech', api_key: '858627157322821'});     
            // Initiate upload
            window.cloudinary.openUploadWidget({ cloud_name: 'www-code4teen-tech', upload_preset: 'csizcotf', tags: ['cgal']}, 
            function(error, result) { 
                if(error) console.log(error);
                // If NO error, log image data to console
                if(result){
              this.setState({
                    cloudImage:result[0].secure_url
                  })
                //console.log(result);
            }
            }.bind(this));
    
    }

async textReader(){

  console.log('spoke')

  jquery.when(window.responsiveVoice.speak(`Ok,`))
  .then(window.responsiveVoice.speak(`${this.state.taskDescription}`))
  .then(window.responsiveVoice.speak(`${this.state.taskA}`))
  .then(window.responsiveVoice.speak(`${this.state.taskB}`))
             
}

async resetSubmissionStatusToFalse(){ 

   let resetQuizStatus = await fetch('https://atcbrain.herokuapp.com/learning-engine/response-api/v1/assessments/unsubmit', { 
    method: "POST",
    headers:{
      'content-type':'application/JSON'
    },
    mode:'cors',
    credentials:'include'
   })
  
   .then(response => response.json())
   .then(response => console.log (response))

}
 
 async liftHtmlState(){
  let thtml =  this.state.html
  console.log(thtml)
 this.props.quizResponseUpdate(thtml, this.props.tag, this.props.assessmentRef)
 }

    
//running the editor code 
      runCode = () => {
        const {html} = this.state;

        const iframe = this.refs.iframe;
        const document = iframe.contentDocument;
        const documentContents = `
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta http-equiv="X-UA-Compatible" content="ie=edge">
            <title>Document</title>
            <style>
           
            </style>
          </head>
          <body>
            ${html}
          </body>
          </html>
        `;

        document.open();
        document.write(documentContents);
        document.close();
      };

      
responsiveClass(){ 

    var loadWidth = '';
    var loadHeight = '';
    var deviceType = null;

    var content = document.getElementById('content');
    var alert = document.getElementById('alert');
    var alertTitle = document.getElementById('alertTitle');
    var alertBody = document.getElementById('alertBody');
    var gif = document.getElementById('gif');
    var body = document.getElementById('body');


    function mobileCheck(deviceType){
        alertTitle.innerHTML = `Use ${deviceType} in landscape mode only`;
        alertBody.innerHTML = `Revert to landscape mode for the best expereince`;
        content.style.display = 'none'; //hide content of webpage
        gif.classList.remove('d-none'); //show rotate gif
        alert.style.display = ''; //show the alert
    }




    //******retrieve width and height of device on page load******
    window.onload = (() => {
        loadWidth = body.offsetWidth;
        loadHeight = body.offsetHeight;

        //retrieve device type
        if (body.offsetWidth > body.offsetHeight && body.offsetWidth > 1024) {
            deviceType = 'desktop'; 
        } else {
            deviceType = 'mobile';
        }

        //event where user loaded page using mobile and in portrait mode
        if (deviceType == 'mobile' && body.offsetHeight > body.offsetWidth) {
            mobileCheck(deviceType);
        }
    })

   

    //***** watch for changes in dimension of the body. If true, call listen(). +added timeout() delay otherwise runs on page load and can't tell 'loadwidth' from 'newWidth'
    setTimeout(() => {
        new ResizeObserver(listen).observe(body);
    }, 1000);



    //******listen to changes****** 
    function listen(){
        var newWidth = body.offsetWidth;
        var newHeight = body.offsetHeight;
        
        if (newWidth !== loadWidth || newHeight !== loadHeight) { 

                //check devicetype and show specific error message
                switch (deviceType) {
                    //when device is a desktop
                    case 'desktop':
                        alertTitle.innerHTML = `Use ${deviceType} in fullscreen mode only`;
                        alertBody.innerHTML = `Revert to original screen size for the best expereince`;
                        gif.classList.add('d-none'); //hide the rotate gif (meant for mobiles only)
                        content.style.display = 'none'; 
                        alert.style.display = '';
                        break;
                    
                        //when device is a mobile
                    case 'mobile':                        
                        if (body.offsetHeight < body.offsetWidth) {
                            //event where user changes from portrait to landscape
                            content.style.display = ''; 
                            alert.style.display = 'none'; 

                        } else {
                            //event where user goes from landscape to portrait
                            mobileCheck(deviceType);
                        }
                        break;

                }
                


        } else {  
                content.style.display = ''; //show content of webpage
                alert.style.display = 'none'; //hide the alert 

                //fixes bug where user came in portrait -> landscape -> portrait 
             //       if (deviceType == 'mobile' && body.offsetHeight > screen.width) {
              //          mobileCheck(deviceType);
                    }
        }
         
    }


//rendering the component as JSX 

      render() {

        
        const beautifyString = beautify(this.props.codeSnippet, {
    "indent_size":2,
    "indent_char": "1",
    "eol": "\\n"
  })
        console.log(beautifyString)
        const { html } = this.state;
        const codeMirrorOptions = {
          mode: 'htmlmixed', 
          tabMode: 'indent',
          value: this.props.codeSnippet,
          theme: 'lucario',
          lineNumbers: true,
          scrollbarStyle: null,
          lineWrapping: true,
          smartIndent: true,
          electricChars: true,
          autofocus: true,
          lineSeparator:"    "
          
        };
       
       const  taskArray1=[ this.props.taskA, this.props.taskB, this.props.taskC, this.props.taskD, this.props.taskE, this.props.taskF, this.props.taskG ]
       const  taskList1 = taskArray1.map(task => <p className="blue-text body-font"> {task} </p> )
       

         
//jsx creation 



        return (
          <div className="standard-padding-tb-2 black-background top-right-curve bottom-right-curve" id="editor-properties">
          <div className="row standard-padding-tb-2" id="editor-instructions"> 
             <div className="col-12 text-right">
               <Badge className="badge badge-danger" variant="danger">Graded</Badge>

             </div>
             <div className="col-12 col-md-2 text-center"> 
             <figure> 
               <button onClick={this.textReader} className="azure-bg" data-toggle="tooltip" data-placement="right" title="Click for voice">
              <img src={atclogo} width="100"/> 
              </button>
              <figcaption className="">
               <p className="body-font yellow-background"> Editor task </p> 
               <p className="accent-font tiny-font white-text"> Complete the tasks by following the instructions </p>
              </figcaption> 
             </figure>
             </div> 
             <div className="col-12 col-md-10 text-center"> 
              <div className="row standard-padding-tb-2 text-center"> 
              <div className="col-12"> 
               <h5 className="body-font blue-text"> {this.props.taskDescription} </h5> 
              </div>
              <div className="col-12 task-steps-box">
                {taskList1}
              </div> 

              <div className="col-12">  
            <button id="upload_widget" className="btn btn-outline-success cloudinary-button" > Upload a picture and recieve a URl below </button>
            <p className="tiny-font margin-off white-text"> {this.state.cloudImage} </p>
            </div>
             
          </div>  

              
               

             </div> 
     
          </div> 
         
          <div className="App row">
         
            <section className="playground col-12 col-md-6">
              <div className="code-editor html-code">
                <div className="editor-header">HTML</div>
                <CodeMirror
                  value={html}
                  options={{
                    mode: 'htmlmixed',
                    ...codeMirrorOptions,
                  }}
                  onBeforeChange={(editor, data, html) => {

                    this.setState({ html }, () => this.liftHtmlState());
                  }}

                  onChange={this.runCode}
                  
                />
              </div>
            </section>
            <section className="result col-12 col-md-6 white-background padding-off">
              <iframe title="result" className="iframe" ref="iframe" />
            </section>
          </div>
          
          </div>
        );

      }
    }

    export default pgassessments2