import React, { Component } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import $ from 'jquery/dist/jquery.js';
import Pusher from 'pusher-js';
import pushid from 'pushid';
import axios from 'axios';
import atclogo from '../../images/loader.png';
import '../../App.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/lucario.css';

import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/css/css';
import 'codemirror/mode/javascript/javascript';

    class Mirror extends Component{
      constructor(props) {
        super(props);
        this.state = {
          id:'',
          html:'',
          resourceRef:this.props.resourceRef,
          title:'',
          codeSnippet:'testing',
          taskDescription:'',
          taskA:'',
          taskB:'',
          taskC:'',
          taskD:'',
          taskE:'',
          taskF:'',
          taskG:'',
          positiveAlert:'',
          negativeAlert:'',
          taskByteValue:'',
          taskRecall:'',
          taskTag:''
        };

  this.fetchEditorResource = this.fetchEditorResource.bind(this)
  this.runCode = this.runCode.bind(this)
  this.textReader = this.textReader.bind(this)
  this.retrievePreviousEditorTaskResponse = this.retrievePreviousEditorTaskResponse.bind(this)
  this.checkForTaskEditorEntry = this.checkForTaskEditorEntry.bind(this)  
  //this.unfreezeRun = this.unfreezeRun.bind(this)

   }

    async componentDidUpdate() {
     
      }

     async componentDidMount() {
    
         console.log(this.state.resourceRef);
        //const {match:{params}} = this.props   
        //console.log(params.topidRef);

           this.props.taskStatus(false)
           await this.fetchEditorResource()
         //  await  this.unfreezeRun()  
          

          
          this.props.byteState(this.state.taskByteValue)

          this.setState({
            positiveAlert:false,
            negativeAlert:false
          })
      }

      async fetchEditorResource() { 
    const requestedResource = await fetch(`https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/${this.state.resourceRef}/editorTask`,{
      method:"POST",
      headers:{
        'Content-Type': "application-JSON"
      }, 
      mode:"cors",
      credentials:"include", 
    })
       .then(response => response.json())
       .then(response => { 
             console.log(response)

             this.setState({ 
                  taskDescription: response.taskDescription,
                  codeSnippet: response.codeSnippet,
                  taskA: response.taskA, 
                  taskB: response.taskB, 
                  taskC: response.taskC, 
                  taskD: response.taskD,
                  taskE: response.taskE,
                  taskF: response.taskF, 
                  taskG: response.taskG,
                  taskByteValue: response.byteValue,
                  taskRecall: response.recall,
                  taskTag : response.tag,
                  
             }, async ()=> { 
                
                 await this.checkForTaskEditorEntry()  
                  
             })

            
       })
 }


 async unfreezeRun(){ 
      console.log(this.state.taskTag)
      const sendCurrentTaskTag = await fetch(`https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/${this.state.taskTag}/unfreezeRunButton`,{
        method:"POST",
        headers:{
          'Content-Type': "application-JSON"
        }, 
        mode:"cors", 
        credentials:"include"
       })
  
        .then(response => response.json())
        .then(response => {
          console.log(response)
          if(response){
            let runButton = document.getElementById('run')
            runButton.removeAttribute('disabled')
          }

          if(!response){
            let runButton = document.getElementById('run')
            runButton.setAttribute('disabled','')
          }

        })

 }


 async checkForTaskEditorEntry(){ 
     const checkForEntry = await fetch(`https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/${this.state.resourceRef}/editorEntryCheck`,{
      method:"POST",
      headers:{
        'Content-Type': "application-JSON"
      }, 
      mode:"cors", 
      credentials:"include"
     })

      .then(response => response.json())
      .then(response => {
        console.log(response)
            if(response.entry){
              this.props.taskStatus(true)
              this.setState({ 
                codeSnippet : response.response
          }, ()=> { 
            let runButton = document.getElementById('run')
            runButton.setAttribute('disabled','')
          })
            }

           if(!response.entry){
            console.log("I ran")
            console.log(this.state.taskRecall)
            if(this.state.taskRecall){
              console.log("I ran 2")
              this.retrievePreviousEditorTaskResponse()
            }
          
           } 
      })

 }


async retrievePreviousEditorTaskResponse(){ 
      
  const requestedEditorResponse = await fetch(`https://atcbrain.herokuapp.com/learning-engine/resource-api/v1/${this.state.taskTag}/editorResponse`,{
      method:"POST",
      headers:{
        'Content-Type': "application-JSON"
      }, 
      mode:"cors", 
      credentials:"include"
    })
       .then(response => response.json())
       .then(response => { 
           console.log(response.response)
           if(response.response){
            this.setState({ 
              codeSnippet : response.response
        })

           }else{
            this.setState({ 
              codeSnippet : ""
        })
  
           }

          
      
 
           
     
                
       })
}

async textReader(){

  console.log('spoke')

  $.when(window.responsiveVoice.speak(`Ok`))
  .then(window.responsiveVoice.speak(`${this.state.taskDescription}`))
  .then(window.responsiveVoice.speak(`${this.state.taskA}`))
  .then(window.responsiveVoice.speak(`${this.state.taskB}`))
             
}

 
 async liftHtmlState(){
  let thtml =  this.state.html
  await thtml.trim()
  console.log(thtml)
  this.props.htmlChange(this.state.html)
 }

    
//running the editor code 
      runCode = () => {

        const {html} = this.state;

        const iframe = this.refs.iframe;
        const document = iframe.contentDocument;
        const documentContents = `
          <!DOCTYPE html>
          <html lang="en">
          <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <meta http-equiv="X-UA-Compatible" content="ie=edge">
            <title>Document</title>
            <style>
           
            </style>
          </head>
          <body>
            ${html}
          </body>
          </html>
        `;

        document.open();
        document.write(documentContents);
        document.close();
       // this.unfreezeRun()
      
      };



responsiveClass(){ 

    var loadWidth = '';
    var loadHeight = '';
    var deviceType = null;

    var content = document.getElementById('content');
    var alert = document.getElementById('alert');
    var alertTitle = document.getElementById('alertTitle');
    var alertBody = document.getElementById('alertBody');
    var gif = document.getElementById('gif');
    var body = document.getElementById('body');


    function mobileCheck(deviceType){
        alertTitle.innerHTML = `Use ${deviceType} in landscape mode only`;
        alertBody.innerHTML = `Revert to landscape mode for the best expereince`;
        content.style.display = 'none'; //hide content of webpage
        gif.classList.remove('d-none'); //show rotate gif
        alert.style.display = ''; //show the alert
    }




    //******retrieve width and height of device on page load******
    window.onload = (() => {
        loadWidth = body.offsetWidth;
        loadHeight = body.offsetHeight;

        //retrieve device type
        if (body.offsetWidth > body.offsetHeight && body.offsetWidth > 1024) {
            deviceType = 'desktop'; 
        } else {
            deviceType = 'mobile';
        }

        //event where user loaded page using mobile and in portrait mode
        if (deviceType == 'mobile' && body.offsetHeight > body.offsetWidth) {
            mobileCheck(deviceType);
        }
    })

   

    //***** watch for changes in dimension of the body. If true, call listen(). +added timeout() delay otherwise runs on page load and can't tell 'loadwidth' from 'newWidth'
    setTimeout(() => {
        new ResizeObserver(listen).observe(body);
    }, 1000);



    //******listen to changes****** 
    function listen(){
        var newWidth = body.offsetWidth;
        var newHeight = body.offsetHeight;
        
        if (newWidth !== loadWidth || newHeight !== loadHeight) { 

                //check devicetype and show specific error message
                switch (deviceType) {
                    //when device is a desktop
                    case 'desktop':
                        alertTitle.innerHTML = `Use ${deviceType} in fullscreen mode only`;
                        alertBody.innerHTML = `Revert to original screen size for the best expereince`;
                        gif.classList.add('d-none'); //hide the rotate gif (meant for mobiles only)
                        content.style.display = 'none'; 
                        alert.style.display = '';
                        break;
                    
                        //when device is a mobile
                    case 'mobile':                        
                        if (body.offsetHeight < body.offsetWidth) {
                            //event where user changes from portrait to landscape
                            content.style.display = ''; 
                            alert.style.display = 'none'; 

                        } else {
                            //event where user goes from landscape to portrait
                            mobileCheck(deviceType);
                        }
                        break;

                }
                


        } else {  
                content.style.display = ''; //show content of webpage
                alert.style.display = 'none'; //hide the alert 

                //fixes bug where user came in portrait -> landscape -> portrait 
             //       if (deviceType == 'mobile' && body.offsetHeight > screen.width) {
              //          mobileCheck(deviceType);
                    }
        }
         
    }

//rendering the component as JSX 

      render() {

           

        const { html } = this.state;
        const codeMirrorOptions = {
          mode: 'htmlmixed', 
          tabMode: 'indent',
          theme: 'lucario',
          value: this.state.codeSnippet,
          lineNumbers: true,
          lineWrapping: true,
          smartIndent: true,
          electricChars: true,
          autofocus: true,
          lineSeparator:"    "
          
        };
       
       const  taskArray1=[ this.state.taskA, this.state.taskB, this.state.taskC, this.state.taskD,this.state.taskE ]
       const  taskArray2=[ this.state.taskE, this.state.taskF, this.state.taskG]
       const  taskList1 = taskArray1.map(task => <p className="blue-text body-font mobile-text-size"> {task} </p> )
       const  taskList2 = taskArray2.map(task => <p className="blue-text body-font mobile-text-size"> {task} </p> )

         
//jsx creation 

        return (
          <div className="standard-padding-tb-2 black-background top-right-curve bottom-right-curve" id="editor-properties">
          <div className="row standard-padding-tb-2" id="editor-instructions"> 
             <div className="col-12 col-md-3 text-center"> 
             <figure> 
              <button onClick={this.textReader} className="azure-bg" data-toggle="tooltip" data-placement="right" title="Click for voice">
              <img src={atclogo} width="100"/> 
              </button>
              <figcaption className="">
               <p className="body-font yellow-background"> Editor task </p> 
               <p className="accent-font tiny-font white-text"> Complete the tasks by following the instructions </p>
              </figcaption> 
             </figure>
             </div> 
             <div className="col-12 col-md-9 text-center"> 
              <div className="row standard-padding-tb-2 text-center"> 
              <div className="col-12"> 
               <h5 className="body-font blue-text mobile-text-size"> {this.state.taskDescription} </h5> 
              </div>
              <div className="col-12 task-steps-box">
                {taskList1}
              </div> 
              <div className="col-12">
            <button id="run" className="btn btn-outline-success" onClick={this.props.validatingResponse}> Run </button>
            </div>
          </div>  

              
               

             </div> 
     
          </div> 
         
          <div className="App row">
         
            <section className="playground col-12 col-md-6">
              <div className="code-editor html-code">
                <div className="editor-header">HTML</div>
                <CodeMirror
                  value={html}
                  options={{
                    mode: 'htmlmixed',
                    ...codeMirrorOptions,
                  }}
                  onBeforeChange={(editor, data, html) => {

                    this.setState({ html }, () => this.liftHtmlState());
                  }}

                  onChange={this.runCode}
                  
                />
              </div>
            </section>
            <section className="result col-12 col-md-6 white-background padding-off">
              <iframe title="result" className="iframe" ref="iframe" />
            </section>
          </div>
          
          </div>
        );
      }
    }

    export default Mirror